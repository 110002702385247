<template>
  <div>

    <CWidgetAnnouncementBar>
      Book & Sail %:&nbsp;<NuxtLink href="/kreuzfahrten" class="text-mongoose-300">Kreuzfahrt-Deals</NuxtLink>
    </CWidgetAnnouncementBar>

    <CPageWrapper no-header>
      <slot />
    </CPageWrapper>

  </div>
</template>
